/* src/App.css */
.uploader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .uploader {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .uploader label {
    cursor: pointer;
  }
  
  .uploader img {
    max-width: 100%;
    margin-top: 16px;
  }
  